var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-manager-container file-manager-grid container-fuild mt-5"},[_c('div',{staticClass:"row"},[(_vm.folder.id)?_c('div',{staticClass:"col-2 mb-4"},[_c('div',{staticClass:"file-item file-item-folder",on:{"dblclick":function($event){return _vm.navigateToParent()}}},[_vm._m(0)])]):_vm._e(),_vm._l((_vm.folder.subfolders),function(folder){return _c('div',{key:`folder-${folder.id}`,staticClass:"col-2 mb-4"},[_c('div',{staticClass:"file-item file-item-folder",class:[
          {
            selected: _vm.selectedItems.some(
              (item) => item.id === folder.id && item.type == 'folders'
            ),
            locked: folder.locked,
          },
        ],on:{"dblclick":function($event){return _vm.navigateToFolder(folder.id)},"click":function($event){return _vm.selectItem(folder)}}},[_c('el-tooltip',{attrs:{"content":folder.name,"placement":"top"}},[_c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-folder"})]),_c('div',{staticClass:"file-item-name"},[_vm._v(" "+_vm._s(folder.name)+" ")]),(
                _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                folder.organization
              )?_c('div',{staticClass:"file-item-organization"},[_c('span',{staticClass:"btn btn-sm btn-info"},[_c('i',{staticClass:"ni ni-building"}),_vm._v(" "+_vm._s(folder.organization.name)+" ")])]):_vm._e()])])],1)])}),_vm._l((_vm.folder.files),function(file){return _c('div',{key:`file-${file.id}`,staticClass:"col-2 mb-4"},[_c('div',{staticClass:"file-item file-item-folder",class:[
          {
            selected: _vm.selectedItems.some(
              (item) => item.id === file.id && item.type == 'files'
            ),
          },
        ],on:{"dblclick":function($event){return _vm.viewFile(file)},"click":function($event){return _vm.selectItem(file)}}},[_c('el-tooltip',{attrs:{"content":file.name,"placement":"top"}},[_c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-file"})]),_c('div',{staticClass:"file-item-name"},[_vm._v(" "+_vm._s(file.name)+" ")]),(
                _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                _vm.folder.organization
              )?_c('div',{staticClass:"file-item-organization"},[_c('span',{staticClass:"btn btn-sm btn-info"},[_c('i',{staticClass:"ni ni-building"}),_vm._v(" "+_vm._s(_vm.folder.organization.name)+" ")])]):_vm._e()])])],1)])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-reply"})])])
}]

export { render, staticRenderFns }